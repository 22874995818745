













































































































































import { Vue, Component } from 'vue-property-decorator';

import { appName } from '@/env';
import { readDashboardMiniDrawer, readDashboardShowDrawer, readHasAdminAccess } from '@/store/main/getters';
import { commitSetDashboardShowDrawer, commitSetDashboardMiniDrawer } from '@/store/main/mutations';
import { dispatchUserLogOut } from '@/store/main/actions';

const routeGuardMain = async (to, from, next) => {
  if (to.path === '/main') next('/main/dashboard');
  else next();
};

interface ToolMenuType {
  id: number;
  title: string;
  icon?: string;
  link?: string;
  children?: ToolMenuType[];
  active?: boolean;
}

@Component
export default class Main extends Vue {
  public appName = appName;

  public toolsMenu: ToolMenuType[] = [
    {
      id: 1,
      title: 'Дашборд',
      icon: 'web',
      link: '/main/dashboard',
    },
    // {
    //   id: 2,
    //   title: 'Telegram',
    //   icon: 'fab fa-telegram-plane',
    //   children: [
    //     {id: 201, title: 'Каналы', link: '/main/telegram-channels'},
    //     {id: 202, title: 'Задачи парсинг', link: '/main/telegram-channels-tasks'},
    //   ],
    // },
    {
      id: 3,
      title: 'Поисковики',
      icon: 'fab fa-yandex',
      link: '/main/se-tasks',
    },
    // {
    //   id: 4,
    //   title: ' Youtube',
    //   icon: 'fab fa-youtube',
    //   link: '/main/youtube',
    // },
    {
      id: 5,
      title: 'Google Search',
      icon: 'fab fa-google',
      link: '/main/google-search',
    },
    {
      id: 6,
      title: 'Telegram',
      icon: 'fab fa-telegram',
      children: [
        {id: 601, title: 'Статистика', link: '/main/telegram'},
        {id: 602, title: 'Парсинг', link: '/main/tg-tasks'},
      ],
    },
    {
      id: 8,
      title: 'YouTube',
      icon: 'fab fa-youtube',
      children: [
        {id: 801, title: 'Статистика', link: '/main/youtube-stat'},
        {id: 802, title: 'Парсинг', link: '/main/youtube-tasks'},
      ],
    },
    {
      id: 10,
      title: 'Отсечки',
      icon: 'fas fa-plug fa-rotate-90',
      // icon: 'fas fa-ban',
      link: '/main/plugs-checker-tasks',
    },
    {
      id: 11,
      title: 'Претензии',
      icon: 'fas fa-envelope',
      link: '/main/claim',
    },
    {
      id: 13,
      title: 'Матрицы',
      icon: 'fas fa-table',
      link: '/main/matrix',
    },
    {
      id: 14,
      title: 'Парсинг Avito',
      icon: 'fas fa-search',
      link: '/main/avito-tasks',
    },
    {
      id: 15,
      title: 'РКН',
      icon: 'fas fa-ban',
      link: '/main/rkn-checker',
    },
    {
      id: 16,
      title: 'Парсинг сайтов',
      icon: 'fas fa-search-plus',
      link: '/main/site-parser',
    },
    {
      id: 17,
      title: 'Траффик сайтов',
      // icon: 'fa fa-signal',
      icon: 'mdi-chart-areaspline',
      link: '/main/site-traffic',
    },
    {
      id: 18,
      title: 'Wordstat',
      icon: 'fa fa-binoculars',
      link: '/main/wordstat',
    },
    {
      id: 19,
      title: 'Регистратор',
      icon: 'fa fa-pen',
      link: '/main/registrar',
    },
  ];

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store),
    );
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store),
    );
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }

}
